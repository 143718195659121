import request from '@/utils/request'


// 一人一档-重点关注
export function zhongDianCount (parameter) {
  return request({
    url: '/Screen/Wj/zhongDianCount',
    method: 'post',
    data: parameter
  })
}

// 一人一档-常住人口
export function liveCount (parameter) {
  return request({
    url: '/Screen/Wj/liveCount',
    method: 'post',
    data: parameter
  })
}

// 一人一档-老幼关注
export function oldCount (parameter) {
  return request({
    url: '/Screen/Wj/oldCount',
    method: 'post',
    data: parameter
  })
}

// 一人一档-社区人口
export function shequCount (parameter) {
  return request({
    url: '/Screen/Wj/shequCount',
    method: 'post',
    data: parameter
  })
}

// 一人一档-党员及志愿者
export function dangyuanCount (parameter) {
  return request({
    url: '/Screen/Wj/dangyuanCount',
    method: 'post',
    data: parameter
  })
}

// 一企一档-管理分类
export function qiyeCount (parameter) {
  return request({
    url: '/Screen/Wj/qiyeCount',
    method: 'post',
    data: parameter
  })
}

// 一企一档-行业分类
export function hangyeCount (parameter) {
  return request({
    url: '/Screen/Wj/hangyeCount',
    method: 'post',
    data: parameter
  })
}

// 一企一档-产业园区
export function yuanquCount (parameter) {
  return request({
    url: '/Screen/Wj/yuanquCount',
    method: 'post',
    data: parameter
  })
}

// 一房一档-常住房屋
export function fangwuCount (parameter) {
  return request({
    url: '/Screen/Wj/fangwuCount',
    method: 'post',
    data: parameter
  })
}


// 人员弹窗列表
export function citizenList (parameter) {
  return request({
    url: '/Screen/Wj/citizenList',
    method: 'post',
    data: parameter
  })
}

// 人员详情
export function citizenDetail (parameter) {
  return request({
    url: '/Screen/Wj/citizenDetail',
    method: 'post',
    data: parameter
  })
}

// 企业列表
export function enterpriseList (parameter) {
  return request({
    url: '/Screen/Wj/enterpriseList',
    method: 'post',
    data: parameter
  })
}

// 企业详情
export function enterpriseDetail (parameter) {
  return request({
    url: '/Screen/Wj/enterpriseDetail',
    method: 'post',
    data: parameter
  })
}

// 房屋列表
export function buildingList (parameter) {
  return request({
    url: '/Screen/Wj/buildingList',
    method: 'post',
    data: parameter
  })
}

// 房屋详情
export function buildingHouseDetail (parameter) {
  return request({
    url: '/Screen/Wj/buildingHouseDetail',
    method: 'post',
    data: parameter
  })
}
