import Vue from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment'

Vue.config.productionTip = false
// import 'lib-flexible'

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

// 时间转换
Vue.filter('momentTime', (e) => {
  return moment(e * 1000).format('HH:mm')
})
import "swiper/css/swiper.css";
// import 'swiper/swiper-bundle.css';


// import AMapLoader from "@amap/amap-jsapi-loader";

// AMapLoader.load({
//   'key': '3e9ea59eb81f1e4558129a6c0bb401ed',
//   'version': '2.0',   // 指定要加载的 JSAPI 的版本，缺少时默认为 1.4.15
//   'plugins': ['AMap.Scale', 'AMap.GeoJSON', 'AMap.DistrictSearch']           // 需要使用的的插件列表，如比例尺'AMap.Scale'等，更多插件请看官方文档
// }).then((AMap) => {
//   Vue.use(AMap)
// })



import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



new Vue({
  router,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
