export const getOption2 = ({seriesData}) => {
  return {
    radar: {
      // shape: 'circle',
      center: ["50%", "56%"],
      splitNumber: 3, // 雷达图圈数设置
      name: {},
      // 设置雷达图中间射线的颜色
      axisLine: {
          lineStyle: {
              color: "transparent",
          },
      },
      indicator: [
          { name: "" },
          { name: "" },
          { name: "" },
          { name: "" },
          { name: "" },
          { name: ""},
          { name: ""},
          { name: ""},
          { name: ""},
      ],
      //雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
      splitArea: {
          show: false,
          areaStyle: {
              color: "transparent", // 图表背景的颜色
          },
      },
      splitLine: {
          show: false,
          lineStyle: {
              width: 1,
              color: "transparent", // 设置网格的颜色
          },
      },
  },
  series:[{
    name: "",
    type: "radar",
    data: seriesData,
    axisLabel: { show: false },
    label: {
      show: false // 将show属性设为false，表示不显示标签
    }
  }]
  }
}
