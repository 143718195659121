export const getOption = ({seriesData}) => {
  return {
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        name: ' ',
        type: 'pie',
        radius: ['40%', '48%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        tooltip: {
          show: false
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: seriesData
      }
    ]
  }
}
