import axios from 'axios'
import md5 from 'md5'
import moment from 'moment'
import { useDateFormat } from '@vueuse/core'
import decrypt from './decrypt'
// 创建 axios 实例
const request = axios.create({
	// API 请求的默认前缀
	baseURL: process.env.VUE_APP_BASE_URL, // 'https://nhzh.wiz.com.cn:8001/',
	timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
	if (error.response) {
		// const data = error.response.data
		// const userToken = localStorage.getItem('userToken')
		// if (data.status!==1) {
		//   notification.error({
		//     message: data.msg,
		//   })
		// }
	}
	return Promise.reject(error)
}

request.interceptors.request.use((config) => {
	const formattedDate = moment(new Date()).format('YYYYMMDDHH')
	const md5Url = config.url.substring(config.url.lastIndexOf('/') + 1)
	const md5Str = md5(formattedDate + md5Url)

	const url = config.url.toLowerCase()
	const date = useDateFormat(new Date(), 'YYYYMMDD').value
	const time = parseInt(new Date().getTime() / 1000) + ''
	const base64Url = btoa(encodeURIComponent(url + date + time).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1)))
	const md5Base64Url = md5(base64Url)
	config.data.request_sign = md5Base64Url.slice(8, 24)

	config.headers['request_sign'] = md5Str.slice(8, 16)
	config.data['timestamp'] = time

	config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
	return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
	const res = !response.data.res ? null : JSON.parse(decrypt(response.data.res))
	// console.log("🚀 ~ request.interceptors.response.use ~ res:", res)
	return { ...response.data, res }
	// if (response.data.status === 1) {
	//   return response.data
	// }else{
	//   // if (response.data.status === -1){}
	//   return response.data
	// }
}, errorHandler)

const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, request)
	}
}

export default request

export { installer as VueAxios, request as axios }
