import moment from 'moment'
import crypto from 'crypto'
import md5 from 'md5'

export default function decrypt (ciphertext, type) {
  if (!ciphertext) {
    return ''
  }
  const formattedDate = moment(new Date()).format('yyyyMMDD')
  // 调用函数进行解密
  const key = md5('key:' + 'BcZ5k!LqqDVx' + formattedDate) // 密钥
  const iv = md5('iv:' + 'Bgt$#wWrwFzy' + formattedDate).substring(8, 24)
  const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv) // 创建解密器对象并指定加密算法、密钥和初始向量
  let plaintext = ''
  try {
    plaintext += decipher.update(ciphertext, 'base64', 'utf8') // 将密文转换为明文
    plaintext += decipher.final('utf8') // 处理最后的数据块
  } catch (error) {
    console.log('解密失败', error)
    plaintext = ''
  }
  switch (type) {
    case 'address':
      return plaintext.replaceAll(/^(.{1}).+(.{1})$/g, '$1****$2')
    case 'idno':
      return plaintext.replaceAll(/^(.{4}).+(.{4})$/g, '$1****$2')
    case 'idcard':
      return plaintext.replaceAll(/^(.{6}).+(.{4})$/g, '$1****$2')
    case 'tel':
      return plaintext.replaceAll(/^(.{3}).+(.{4})$/g, '$1****$2')
    default:
      return plaintext
  }
}
