<template>
	<transition name="fade">
		<div v-if="isShow" class="modal-mask" @click="tapMask">
			<!-- 阻止事件冒泡 -->
			<div @click.stop="">
				<slot></slot>
			</div>
		</div>
	</transition>
</template>


<script>
	export default {
		name: 'JJPop',
		props: {
			isShow: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {

			};
		},
		methods: {
			tapMask() {
				this.$emit('tapMask');
			}
		}
	};
</script>


<style scoped lang="less">
	.modal-mask {
		position: fixed;
		z-index: 9998;
		top: 0;
		left: 0;
		width: 193rem;
		height: 54rem;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.fade-enter-active, .fade-leave-active {
	  transition: opacity 0.25s;
	}
	.fade-enter, .fade-leave-to {
	  opacity: 0;
	}
</style>