<template>
	<div class="dialog-view">
		<div class="dialog-view-header">
			<div class="header-left">
				<div class="line"></div>
				<div class="title">房屋详情</div>
			</div>
			<img @click="closeView" class="close-btn" src="~@/assets/images/center3.png" />
		</div>
		<div class="dialog-view-select">
			<div class="select-item select-action">基本信息</div>
			<div class="select-item">人员信息</div>
			<div class="select-item">出租信息</div>
			<div class="select-item">走访信息</div>
			<div class="select-item">涉事信息</div>
		</div>
		
		<div class="detail-view">
			<div class="detail-view-img">
				<img class="img" :src="info.images">
			</div>
			<div class="info-view">
				<div class="name-view">
					<div class="name">
						户主（产权人）：{{info.name}}
					</div>
				</div>
				<div class="info-line">
					<div class="title">联系方式：</div>
					<div class="num-value">{{info.mobile}}</div>
				</div>
				<div class="xuxian"></div>
				<div class="info-line" style="margin-top: 0;">
					<div class="title">房屋类型：</div>
					<div class="num-value">{{info.street_name}}</div>
					<div class="fengexian"></div>
					<div class="title">产权编号：</div>
					<div class="num-value">{{info.street_name}}</div>
				</div>
			</div>
		</div>	
		
	</div>	
</template>

<script>
	import {buildingHouseDetail} from '@/api/indexCenter'
	export default {
		name: 'BuildingDetail',
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				info:{}
			};
		},
		mounted(){
			this.getDetail()
		},
		methods: {
			getDetail(){
				buildingHouseDetail({
					buildingHouseId: this.id,
				}).then((res) => {
				    if (res.status === 1) {
						this.info = res.res
				    }
				 })
			},
			closeView() {
				this.$emit("close")
			}
	
		}
	};
</script>

<style scoped lang="less">
	.dialog-view {
		width: 65rem;
		height: 43rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-image: url("../assets/images/center1.png");
		background-size: 100% 100%;
		padding: 0 2rem;

		.dialog-view-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 96%;
			padding: 0 1.7rem;
			margin-top: 5rem;

			.header-left {
				display: flex;
				flex-direction: row;

				.line {
					width: .4rem;
					height: 1.2rem;
					background: linear-gradient(180deg, #FFFFFF 0%, #06F8FF 100%);
					box-shadow: .15rem 0rem .4rem 0rem rgba(22, 248, 255, 0.8);
				}

				.title {
					font-size: 1.3rem;
					font-family: SourceHanSansCN, SourceHanSansCN;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 1.4rem;
					margin-left: 1rem;
				}
			}

			.close-btn {
				width: 1.6rem;
				height: 1.6rem;
			}
		}

		.dialog-view-select {
			display: flex;
			flex-direction: row;
			width: 90%;
			margin-top: 1.5rem;
			align-items: center;
			justify-content: center;

			.select-item {
				width: 10%;
				height: 3rem;
				line-height: 3rem;
				text-align: center;
				font-size: 1.1rem;
				color: #fff;
				margin: 0 .6rem;
				
				background: #0E2752;
				box-shadow: inset 0rem 0rem .5rem 0rem rgba(255,255,255,0.2);
				border-radius: .2rem;
				border: .1rem solid;
				border-image: linear-gradient(180deg, rgba(62, 142, 216, 1), rgba(25, 74, 153, 1)) 2 2;
			}

			.select-action {
				background: linear-gradient(180deg, #185197 0%, #0B2B5D 100%);
				box-shadow: inset 0rem 0rem .6rem 0rem rgba(80,167,255,0.59);
				border-radius: .2rem;
				border: .1rem solid;
				border-image: linear-gradient(180deg, rgba(62, 142, 216, 1), rgba(25, 74, 153, 1)) 2 2;
			}
		}

		.detail-view {
			display: flex;
			flex-direction: row;
			margin-top: 1.5rem;
			width: 57.5rem;
			height: 26rem;
			background: linear-gradient(90deg, rgba(1,38,94,0.8) 0%, rgba(0,24,61,0.2) 100%);
			box-shadow: inset 0rem 0rem .8rem 0rem rgba(77,91,149,0.4);
			border-radius: .2rem;
			border: .05rem solid;
			border-image: linear-gradient(90deg, rgba(33, 95, 193, 1), rgba(33, 95, 193, 0.4)) 1 1;
			padding: 1.5rem;

			.detail-view-img {
				border-radius: .25rem;
				overflow: hidden;
				.img {
					width: 9rem;
					height: 9rem;
					object-fit: contain;
				}
			}
			.info-view{
				width: 45rem;
				height: 23.5rem;
				margin-left: 1.5rem;
				display: flex;
				flex-direction: column;
				.name-view{
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					.name{
						font-size: 1.4rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: bold;
						color: #FFFFFF;
						margin-right: .5rem;
					}
					.yyzz{
						display: flex;
						flex-direction: row;
						align-items: center;
						.img{
							width: 1.2rem;
							height: 1.2rem;
						}
						.text{
							font-size: 1.3rem;
							font-family: SourceHanSansCN, SourceHanSansCN;
							font-weight: 400;
							color: #DEF1FF;
							background: linear-gradient(180deg, #FFFFFF 0%, #FFD200 100%);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							margin-left: .5rem;
						}
					}
					.label-view{
						font-size: 1.2rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: 500;
						color: rgba(222, 241, 255, 1);
						text-shadow: 0rem .05rem .15rem rgba(0,0,0,0.4);
						padding-top: .5rem;
						padding-left: 1rem;
						margin-left: .9rem;
						background-repeat: no-repeat;
					}
				}
				.info-line{
					margin-top: 1.3rem;
					display: flex;
					flex-direction: row;
					align-items: center;
					.title{
						font-size: 1.2rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: 400;
						color: #DEF1FF;
					}
					.num-value{
						margin-left: .3rem;
						font-size: 1.2rem;
						font-family: Impact;
						color: #FFFFFF;
						background: linear-gradient(180deg, #FFFFFF 0%, #7BDCFF 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					.fengexian{
						width: .1rem;
						height: 1.2rem;
						background-color: #ccc;
						margin: 0 1.5rem;
					}
					.address-icon{
						width: 1.1rem;
						height: 1.1rem;
					}
					.address-text{
						font-size: 1.2rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: 400;
						color: #DEF1FF;
						margin-left: .5rem;
					}
				}
				.xuxian{
					border-top: .05rem dashed #ccc;
					width: 42.5rem;
					height: 0;
					margin: 1.5rem 0;

				}
				
			}
		}


	}
	.bigimg{
		width: 56.8rem;
		height: 40rem;
	}
</style>