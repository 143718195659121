<template>
  <div
    class="index_container"
    :style="{ 'background-image': `url(${require('@/assets/images/bg.png')})` }"
  >
    <!-- <div
      class="index_header flex"
      :style="{
        'background-image': `url(${require('@/assets/images/title_bg.png')})`,
      }"
    >
      <img src="~@/assets/images/title.png" class="title" />
    </div> -->
    <img src="~@/assets/images/bottom_bg.png" class="bottom_bg" />
    <!-- left1 -->
    <div class="index_content flex between">
      <div class="flex">
        <div class="left_1_box">
          <div class="xiaoqu_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg.png" />
              <span>小区信息</span>
            </div>
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="xiaoqu_item_box">
                    <div class="item align-center between">
                      <div class="icon_text align-center">
                        <img src="~@/assets/images/q_1.png" />
                        <div class="text_box">
                          <span>楼宇</span>
                          <span>总数</span>
                        </div>
                      </div>
                      <div class="num_box">{{ xiaoquData.building_num }}</div>
                    </div>
                    <div class="item align-center between">
                      <div class="icon_text align-center">
                        <img src="~@/assets/images/q_2.png" />
                        <div class="text_box">
                          <span>登记</span>
                          <span>车辆</span>
                        </div>
                      </div>
                      <div class="num_box">{{ xiaoquData.car_num }}</div>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="xiaoqu_item_box">
                    <div class="item align-center between">
                      <div class="icon_text align-center">
                        <img src="~@/assets/images/q_1.png" />
                        <div class="text_box">
                          <span>房屋</span>
                          <span>总数</span>
                        </div>
                      </div>
                      <div class="num_box">{{ xiaoquData.house_num }}</div>
                    </div>
                    <div class="item align-center between">
                      <div class="icon_text align-center">
                        <img src="~@/assets/images/q_2.png" />
                        <div class="text_box">
                          <span>商铺</span>
                          <span>总数</span>
                        </div>
                      </div>
                      <div class="num_box">{{ xiaoquData.shop_num }}</div>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="xiaoqu_item_box">
                    <div class="item align-center between">
                      <div class="icon_text align-center">
                        <img src="~@/assets/images/q_1.png" />
                        <div class="text_box">
                          <span>单元</span>
                          <span>总数</span>
                        </div>
                      </div>
                      <div class="num_box">{{ xiaoquData.cell_num }}</div>
                    </div>
                    <div class="item align-center between">
                      <div class="icon_text align-center">
                        <img src="~@/assets/images/q_2.png" />
                        <div class="text_box">
                          <span>车位</span>
                          <span>总数</span>
                        </div>
                      </div>
                      <div class="num_box">
                        {{ xiaoquData.car_parking_num }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="swiper-button-next danyuan_next" slot="button-next">
                <img src="~@/assets/images/dy_right.png" />
              </div>
              <div class="swiper-button-prev danyuan_prev" slot="button-prev">
                <img src="~@/assets/images/dy_left.png" />
              </div>
            </div>
          </div>

          <div class="renyuan_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg.png" />
              <span>人员信息</span>
            </div>
            <div class="renyaun_item_box">
              <div
                class="rychart_box pie_chart_box"
                :style="{
                  'background-image': `url(${require('@/assets/images/bg_1.png')})`,
                }"
              >
                <div id="rychart"></div>
              </div>
              <div class="num_box">
                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_1"></div>
                    <span>常住人口</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_1">{{
                      citizenInfoData.total_num
                    }}</span>
                  </div>
                </div>

                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_2"></div>
                    <span>暂住人口</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_2">{{
                      citizenInfoData.zanzhu_num
                    }}</span>
                  </div>
                </div>

                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_3"></div>
                    <span>寄住人口</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_3">{{
                      citizenInfoData.jizhu_num
                    }}</span>
                  </div>
                </div>

                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_4"></div>
                    <span>户籍人口</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_4">{{
                      citizenInfoData.huji_num
                    }}</span>
                  </div>
                </div>

                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_5"></div>
                    <span>外籍人口</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_5">{{
                      citizenInfoData.zanzhu_num
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="zhongdian_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg.png" />
              <span>重点人员管控</span>
            </div>
            <div class="zhongdian_item_box align-center between">
              <div class="zd_num_box">
                <div
                  class="num_box"
                  :style="{
                    'background-image': `url(${require('@/assets/images/zd_icon.png')})`,
                  }"
                >
                  <div class="num">{{ zhongdianDataData.total_num }}</div>
                  <div class="text">重点人员</div>
                </div>
                <div
                  class="num_box"
                  :style="{
                    'background-image': `url(${require('@/assets/images/zd_icon.png')})`,
                  }"
                >
                  <div class="num">{{ zhongdianDataData.dongtai_num }}</div>
                  <div class="text">动态信息</div>
                </div>
              </div>
              <div
                :style="{
                  'background-image': `url(${require('@/assets/images/zd_bg.png')})`,
                }"
                class="zd_table_box"
              >
                <div class="th_box align-center">
                  <span>姓名</span>
                  <span>出行时间</span>
                </div>
                <div class="td_box" v-if="zhongdianDataData.list">
                  <div
                    class="item align-center"
                    v-for="(item, index) in zhongdianDataData.list"
                    :key="index"
                  >
                    <div class="name">{{ item.name }}</div>
                    <div class="center">
                      <div
                        :style="{
                          'background-image': `url(${require('@/assets/images/zd_text.png')})`,
                        }"
                        class="time_yellow_bg center"
                      >
                        {{ item.swipe_time | momentTime }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="left_2_box">
          <div class="shezhi_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg_2.png" />
              <span>社区设施</span>
            </div>
            <div class="shezhi_info_box align-center between">
              <div class="sheshi_item_box">
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_1.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>监控总数</span>
                  <span>{{ facilityInfoData.jiankong_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_2.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>垃圾配套</span>
                  <span>{{ facilityInfoData.laji_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_3.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>犬只数量</span>
                  <span>{{ facilityInfoData.dog_num }}</span>
                </div>
              </div>
              <div
                class="rychart_box pie_chart_box_2"
                :style="{
                  'background-image': `url(${require('@/assets/images/ss_bg.png')})`,
                }"
              >
                <div id="ss_chart"></div>
              </div>
              <div class="sheshi_item_box">
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_4.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>消防设施</span>
                  <span>{{ facilityInfoData.xiaofang_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_5.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>快递驿站</span>
                  <span>{{ facilityInfoData.kuaidi_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_6.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>人像门禁</span>
                  <span>{{ facilityInfoData.menjin_num }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="renyuan_info cheliang_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg_2.png" />
              <span>车辆管理</span>
            </div>
            <div class="renyaun_item_box">
              <div
                class="rychart_box pie_chart_box"
                :style="{
                  'background-image': `url(${require('@/assets/images/cl_bg.png')})`,
                }"
              >
                <div id="clchart"></div>
              </div>
              <div class="num_box">
                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_1"></div>
                    <span>登记车辆</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_1">{{ carInfoData.total }}</span>
                  </div>
                </div>

                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_2"></div>
                    <span>外地车辆</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_2">{{ carInfoData.waidi }}</span>
                  </div>
                </div>

                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_3"></div>
                    <span>本地车辆</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_3">{{ carInfoData.bendi }}</span>
                  </div>
                </div>

                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_4"></div>
                    <span>黄牌车辆</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_4">{{ carInfoData.huangpai }}</span>
                  </div>
                </div>

                <div class="item">
                  <div class="num_text align-center">
                    <div class="ry_color_5"></div>
                    <span>绿牌车辆</span>
                  </div>
                  <div class="num align-center">
                    <div></div>
                    <span class="ry_text_5">{{ carInfoData.lvpai }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="zhongdian_ry_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg.png" />
              <span>重点人员管控</span>
            </div>
            <div class="zhongdian_item_box align-center between">
              <div class="zd_num_box">
                <div
                  class="num_box"
                  :style="{
                    'background-image': `url(${require('@/assets/images/zd_icon.png')})`,
                  }"
                >
                  <div class="num">{{ oldFocusData.old_num }}</div>
                  <div class="text">关注老人</div>
                </div>
                <div
                  class="num_box"
                  :style="{
                    'background-image': `url(${require('@/assets/images/zd_icon.png')})`,
                  }"
                >
                  <div class="num">{{ oldFocusData.young_num }}</div>
                  <div class="text">关注幼儿</div>
                </div>
              </div>
              <div
                :style="{
                  'background-image': `url(${require('@/assets/images/zd_bg.png')})`,
                }"
                class="zd_table_box"
              >
                <div class="th_box align-center">
                  <span>姓名</span>
                  <span>出行时间</span>
                </div>
                <div class="td_box" v-if="oldFocusData.out_list">
                  <div
                    class="item align-center"
                    v-for="(item, index) in oldFocusData.out_list"
                    :key="index"
                  >
                    <div class="name">{{ item.name }}</div>
                    <div class="center">
                      <div
                        :style="{
                          'background-image': `url(${require('@/assets/images/zd_text.png')})`,
                        }"
                        class="time center"
                      >
                        {{ item.swipe_time | momentTime }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="center_box">

        <div class="center_bottom_box">
          <div class="sub_menu flex">
            <div
              :style="{
                'background-image':
                  bottomMenuIndex == 0
                    ? `url(${require('@/assets/images/btn_4.png')})`
                    : `url(${require('@/assets/images/btn_3.png')})`,
              }"
              class="sub_button center"
              :class="bottomMenuIndex == 0 ? 'active_sub_button' : ''"
              @click="selectBottomMenu(0)"
            >
              一人一档
            </div>
            <div
              :style="{
                'background-image':
                  bottomMenuIndex == 1
                    ? `url(${require('@/assets/images/btn_4.png')})`
                    : `url(${require('@/assets/images/btn_3.png')})`,
              }"
              class="sub_button center"
              :class="bottomMenuIndex == 1 ? 'active_sub_button' : ''"
              @click="selectBottomMenu(1)"
            >
              一企一档
            </div>
            <div
              :style="{
                'background-image':
                  bottomMenuIndex == 2
                    ? `url(${require('@/assets/images/btn_4.png')})`
                    : `url(${require('@/assets/images/btn_3.png')})`,
              }"
              class="sub_button center"
              :class="bottomMenuIndex == 2 ? 'active_sub_button' : ''"
              @click="selectBottomMenu(2)"
            >
              一物一档
            </div>
            <div
              :style="{
                'background-image':
                  bottomMenuIndex == 3
                    ? `url(${require('@/assets/images/btn_4.png')})`
                    : `url(${require('@/assets/images/btn_3.png')})`,
              }"
              class="sub_button center"
              :class="bottomMenuIndex == 3 ? 'active_sub_button' : ''"
              @click="selectBottomMenu(3)"
            >
              一房一档
            </div>
          </div>
          <div
            class="main_menu flex"
           
          >
            <div
              v-for="(item, index) in bottomSecondMenuData"
              :style="{
                'background-image':
                  bottomSecondMenuIndex == index
                    ? `url(${require('@/assets/images/btn_1.png')})`
                    : `url(${require('@/assets/images/btn_2.png')})`,
              }"
              class="main_button center"
              :class="
                bottomSecondMenuIndex == index ? 'active_main_button' : ''
              "
              @click="selectSecondMenu(item, index)"
              
            >
              {{ item }}
            </div>
          </div>
          
        </div>
        <div class="center_box_header center" v-if="bottomSecondMenuData.length">
          <img src="@/assets/images/downshow.png" class="downshow-icon" :class="{isDown:bottomShow}" @click="bottomShow = !bottomShow" alt="">
          <!-- <img src="~@/assets/images/center_bg.png" class="center_bg" /> -->
          <div class="center_header_content flex" v-if="bottomShow">
  
            <div v-for="(item, index) in centerTopCountData" :key="index">
              <div class="item align-center" @click="showDialogFun">
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/center_1.png')})`,
                  }"
                  class="center"
                >
                  <p>{{ item.title }}</p>
                </div>
                <span>{{ item.num }}</span>
              </div>
              <!-- <div class="line"></div> -->
            </div>
          </div>
        </div>
        <div class="map_box">
          <MapContianer />
        </div>


      </div>

      <div class="flex">
        <div class="left_3_box">
          <div class="shezhi_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg_2.png" />
              <span>智慧警网</span>
            </div>
            <div class="shezhi_info_box align-center between">
              <div class="sheshi_item_box">
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_1.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>处置中事件</span>
                  <span>{{ smartPoliceData.czz_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_2.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>事件分流</span>
                  <span>{{ smartPoliceData.fenliu_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_3.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>矛盾联调</span>
                  <span>{{ smartPoliceData.mdlt_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_1.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>警情联处</span>
                  <span>{{ smartPoliceData.jqlc_num }}</span>
                </div>
              </div>
              <div
                class="rychart_box pie_chart_box_2"
                :style="{
                  'background-image': `url(${require('@/assets/images/zh_bg.png')})`,
                }"
              >
                <div id="zh_chart"></div>
              </div>
              <div class="sheshi_item_box">
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_4.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>已处置事件</span>
                  <span>{{ smartPoliceData.ycz_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_5.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>专项处置</span>
                  <span>{{ smartPoliceData.zxcz_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_6.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>隐患联排</span>
                  <span>{{ smartPoliceData.yhlp_num }}</span>
                </div>
                <div
                  :style="{
                    'background-image': `url(${require('@/assets/images/ss_7.png')})`,
                  }"
                  class="align-center between"
                >
                  <span>防范联宣</span>
                  <span>{{ smartPoliceData.fflx_num }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="maodun_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg_2.png" />
              <span>矛盾防范</span>
            </div>
            <div
              class="md_icon align-center between"
              :style="{
                'background-image': `url(${require('@/assets/images/md_icon.png')})`,
              }"
            >
              <span>累计处置事件：{{ maodunDataData.total_num }}</span>
              <span>本月新增事件：{{ maodunDataData.month_num }}</span>
            </div>
            <div
              class="md_table_box"
              :style="{
                'background-image': `url(${require('@/assets/images/md_table.png')})`,
              }"
            >
              <div class="th_box align-center between">
                <span>姓名</span>
                <span>事件简述</span>
                <span>事件来源</span>
              </div>
              <div class="td_box" v-if="maodunDataData.list">
                <div
                  class="item align-center between"
                  v-for="(item, index) in maodunDataData.list"
                  :key="index"
                >
                  <div class="name">{{ item.name }}</div>
                  <div class="name text-cut">{{ item.title }}</div>
                  <div
                    :style="{
                      'background-image': `url(${require('@/assets/images/zd_text.png')})`,
                    }"
                    class="time_yellow_bg center"
                  >
                    <span v-if="item.event_source == 1">自主</span>
                    <span v-else-if="item.event_source == 2">领导</span>
                    <span v-else-if="item.event_source == 3">巡查</span>
                    <span v-else-if="item.event_source == 4">居民</span>
                    <span v-else-if="item.event_source == 5">城管</span>
                    <span v-else-if="item.event_source == 6">上级</span>
                    <span v-else-if="item.event_source == 7">媒体</span>
                    <span v-else-if="item.event_source == 8">物业</span>
                    <span v-else-if="item.event_source == 9">信访</span>
                    <span v-else-if="item.event_source == 10">12345</span>
                    <span v-else-if="item.event_source == 11">12319</span>
                    <span v-else-if="item.event_source == 12">警务</span>
                    <span v-else-if="item.event_source == 13">平台</span>
                    <span v-else-if="item.event_source == 14">APP</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="shenye_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg_2.png" />
              <span>深夜出行</span>
            </div>
            <div
              class="md_table_box"
              :style="{
                'background-image': `url(${require('@/assets/images/sy_table.png')})`,
              }"
            >
              <div class="th_box align-center between">
                <span>姓名</span>
                <span>采集位置</span>
                <span>出行频次</span>
                <span>出行时间</span>
              </div>
              <div class="td_box">
                <div
                  class="item align-center between"
                  v-for="(item, index) in nightRecordData"
                  :key="index"
                >
                  <div>{{ item.name }}</div>
                  <div class="text-cut">{{ item.address }}</div>
                  <div
                    :style="{
                      'background-image': `url(${require('@/assets/images/zd_text.png')})`,
                    }"
                    class="time_yellow_bg center"
                  >
                    {{ item.fre }}
                  </div>
                  <div
                    :style="{
                      'background-image': `url(${require('@/assets/images/zd_text.png')})`,
                    }"
                    class="time_yellow_bg center"
                  >
                    {{ item.swipe_time | momentTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="left_4_box">
          <div class="jingwang_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg_2.png" />
              <span>智慧警网</span>
            </div>
            <div class="jingwang_info_box align-center">
              <div class="jw_num_box flex between">
                <div class="item center">
                  <span class="color_1">{{ smartPoliceData.zhixing_num }}</span>
                  <span>执行事件</span>
                </div>
                <div class="item center">
                  <span class="color_2">{{ smartPoliceData.lishi_num }}</span>
                  <span>历史事件</span>
                </div>
                <div class="item center">
                  <span class="color_3">{{ smartPoliceData.old_num }}</span>
                  <span>关注老人</span>
                </div>
                <div class="item center">
                  <span class="color_4">{{ smartPoliceData.young_num }}</span>
                  <span>关注幼儿</span>
                </div>
              </div>

              <div
                class="jingwang_box"
                :style="{
                  'background-image': `url(${require('@/assets/images/jw_bg.png')})`,
                }"
              >
                <div id="jw_chart" class="jw_chart"></div>
                <div class="name_text text_1">
                  <span>{{ enterpriseDataData.qiyeNum }}</span>
                  <span>企业</span>
                </div>
                <div class="name_text text_2">
                  <span>{{ enterpriseDataData.danweiNum }}</span>
                  <span>单位</span>
                </div>
                <div class="name_text text_3">
                  <span>{{ enterpriseDataData.gonggongNum }}</span>
                  <span>公共场所</span>
                </div>
                <div class="name_text text_4">
                  <span>{{ enterpriseDataData.shagnhuNum }}</span>
                  <span>商户</span>
                </div>
                <div class="name_text text_5">
                  <span>{{ enterpriseDataData.canyinNum }}</span>
                  <span>餐饮</span>
                </div>
                <div class="name_text text_6">
                  <span>{{ enterpriseDataData.qipaiNum }}</span>
                  <span>棋牌室</span>
                </div>
                <div class="name_text text_7">
                  <span>{{ enterpriseDataData.xiyuNum }}</span>
                  <span>洗浴</span>
                </div>
                <div class="name_text text_8">
                  <span>{{ enterpriseDataData.xiaofangNum }}</span>
                  <span>消防单位</span>
                </div>
                <div class="name_text text_9">
                  <span>{{ enterpriseDataData.neibaoNum }}</span>
                  <span>内保单位</span>
                </div>
              </div>
            </div>
          </div>

          <div class="yichang_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg_2.png" />
              <span>异常活动监测</span>
            </div>
            <div
              class="md_icon align-center between"
              :style="{
                'background-image': `url(${require('@/assets/images/md_icon.png')})`,
              }"
            >
              <span>监测事件：{{ abnormalDataData.total_num }}</span>
              <span>监测单位：{{ abnormalDataData.danwei_num }}</span>
            </div>
            <div class="yc_info_box" v-if="abnormalDataData.list">
              <div
                class="item align-center between"
                v-for="(item, index) in abnormalDataData.list"
                :key="index"
              >
                <img
                  src="~@/assets/images/yc_1.png"
                  class="icon"
                  v-if="item.event_level == 1"
                />
                <img
                  src="~@/assets/images/yc_2.png"
                  class="icon"
                  v-else-if="item.event_level == 2"
                />
                <img src="~@/assets/images/yc_3.png" class="icon" v-else />
                <div
                  class="text_box"
                  :style="{
                    'background-image': `url(${require('@/assets/images/yc_bg.png')})`,
                  }"
                >
                  <div class="name_box align-center between">
                    <div class="name">涉警单位</div>
                    <div class="info align-center">
                      <span>详情</span>
                      <img src="~@/assets/images/yc_icon.png" class="icon" />
                    </div>
                  </div>
                  <div class="address align-center">
                    {{ item.title }} ｜ {{ item.event_time }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="shenye_info">
            <div class="content_title align-center">
              <img src="~@/assets/images/title_name_bg_2.png" />
              <span>精准采集</span>
            </div>
            <div
              class="md_table_box"
              :style="{
                'background-image': `url(${require('@/assets/images/sy_table.png')})`,
              }"
            >
              <div class="th_box align-center between">
                <span>采集类型</span>
                <span>采集位置</span>
                <span>采集时间</span>
                <span>次数</span>
              </div>
              <div class="td_box">
                <div
                  class="item align-center between"
                  v-for="(item, index) in collectDataData"
                  :key="index"
                >
                  <div class="text-cut">{{ item.type_name }}</div>
                  <div class="text-cut">{{ item.address }}</div>
                  <div
                    :style="{
                      'background-image': `url(${require('@/assets/images/zd_text.png')})`,
                    }"
                    class="time_yellow_bg center"
                  >
                    {{ item.swipe_time | momentTime }}
                  </div>
                  <div
                    :style="{
                      'background-image': `url(${require('@/assets/images/zd_text.png')})`,
                    }"
                    class="time_yellow_bg center"
                  >
                    {{ item.num }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <JJPop :isShow="showDialog" @tapMask="showDialog = false">
      <div class="dialog-view">
        <div class="dialog-view-header">
          <div class="header-left">
            <div class="line"></div>
            <div v-if="bottomMenuIndex == 0" class="title">人员列表</div>
            <div v-if="bottomMenuIndex == 1" class="title">企业列表</div>
            <div v-if="bottomMenuIndex == 2" class="title">列表</div>
            <div v-if="bottomMenuIndex == 3" class="title">房屋列表</div>
          </div>
          <img
            @click="showDialog = false"
            class="close-btn"
            src="~@/assets/images/center3.png"
          />
        </div>
        <div class="search-view">
          <!-- 人员列表搜索 -->
          <template v-if="bottomMenuIndex == 0">
            <div class="search-view-item search-view-item-name">
              <img class="search-icon" src="~@/assets/images/center2.png" />
              <input
                v-model="dialogSearch.name"
                class="search-input"
                placeholder="姓名"
                type="text"
              />
            </div>
            <div class="search-view-item search-view-item-phone">
              <img class="search-icon" src="~@/assets/images/center2.png" />
              <input
                v-model="dialogSearch.phone"
                class="search-input"
                placeholder="手机号"
                type="text"
              />
            </div>
            <div class="search-view-item search-view-item-idcard">
              <img class="search-icon" src="~@/assets/images/center2.png" />
              <input
                v-model="dialogSearch.idcard"
                class="search-input"
                placeholder="身份证号"
                type="text"
              />
            </div>
          </template>
          <!-- 企业列表搜索 -->
          <template v-if="bottomMenuIndex == 1">
            <div class="search-view-item search-view-item-idcard">
              <img class="search-icon" src="~@/assets/images/center2.png" />
              <input
                v-model="dialogSearch.name"
                class="search-input"
                placeholder="名称"
                type="text"
              />
            </div>
            <div class="search-view-item search-view-item-address">
              <img class="search-icon" src="~@/assets/images/center2.png" />
              <input
                v-model="dialogSearch.address"
                class="search-input"
                placeholder="地址"
                type="text"
              />
            </div>
          </template>
          <!-- 房屋列表搜索 -->
          <template v-if="bottomMenuIndex == 3">
            <div class="search-view-item search-view-item-phone">
              <img class="search-icon" src="~@/assets/images/center2.png" />
              <input
                v-model="dialogSearch.name"
                class="search-input"
                placeholder="户主姓名"
                type="text"
              />
            </div>
            <div class="search-view-item search-view-item-address">
              <img class="search-icon" src="~@/assets/images/center2.png" />
              <input
                v-model="dialogSearch.address"
                class="search-input"
                placeholder="地址"
                type="text"
              />
            </div>
          </template>
          <div class="search-btn" @click="searchData"><span>查询</span></div>
          <div
            class="search-btn"
            @click="resetDialogSearch"
            style="margin-right: 0"
          >
            <span>重置</span>
          </div>
        </div>
        <div class="list-view">
          <!-- 人员列表 -->
          <template v-if="bottomMenuIndex == 0">
            <div class="item">
              <div class="sub-item name">姓名</div>
              <div class="sub-item idcard">身份证号</div>
              <div class="sub-item phone">手机号</div>
              <div class="sub-item operate">操作</div>
            </div>
            <div class="item" v-for="(item, index) in dialogListData.data">
              <div class="sub-item name">{{ item.name }}</div>
              <div class="sub-item idcard">{{ item.paper_number_hidden }}</div>
              <div class="sub-item phone">{{ item.mobile }}</div>
              <div class="sub-item operate">
                <span class="operate-btn" @click="showDetail(0, item)"
                  >查看</span
                >
              </div>
            </div>
          </template>
          <!-- 企业列表 -->
          <template v-if="bottomMenuIndex == 1">
            <div class="item">
              <div class="sub-item name">名称</div>
              <div class="sub-item idcard">地址</div>
              <div class="sub-item phone">法人</div>
              <div class="sub-item operate">操作</div>
            </div>
            <div class="item" v-for="(item, index) in dialogListData.data">
              <div class="sub-item name">{{ item.name }}</div>
              <div class="sub-item idcard">{{ item.address }}</div>
              <div class="sub-item phone">{{ item.chargeName }}</div>
              <div class="sub-item operate">
                <span class="operate-btn" @click="showDetail(1, item)"
                  >查看</span
                >
              </div>
            </div>
          </template>
          <!-- 房屋列表 -->
          <template v-if="bottomMenuIndex == 3">
            <div class="item">
              <div class="sub-item address">地址</div>
              <div class="sub-item phone">户主（产权人）</div>
              <div class="sub-item operate">操作</div>
            </div>
            <div class="item" v-for="(item, index) in dialogListData.data">
              <div class="sub-item address">{{ item.address }}</div>
              <div class="sub-item phone">{{ item.name }}</div>
              <div class="sub-item operate">
                <span class="operate-btn" @click="showDetail(3, item)"
                  >查看</span
                >
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="dialogListData.data && dialogListData.data.length > 0"
          class="pagination"
        >
          <el-pagination
            @current-change="paginationChange"
            background
            layout="prev, pager, next"
            :current-page="dialogSearch.page"
            :total="dialogListData.total"
          >
          </el-pagination>
        </div>
      </div>
    </JJPop>

    <JJPop :isShow="showPersonDetail" @tapMask="showPersonDetail = false">
      <PersonDetail
        @close="showPersonDetail = false"
        :id="selectItemId"
      ></PersonDetail>
    </JJPop>

    <JJPop
      :isShow="showEnterpriseDetail"
      @tapMask="showEnterpriseDetail = false"
    >
      <EnterpriseDetail
        @close="showEnterpriseDetail = false"
        :id="selectItemId"
      ></EnterpriseDetail>
    </JJPop>

    <JJPop :isShow="showBuildingDetail" @tapMask="showBuildingDetail = false">
      <BuildingDetail
        @close="showBuildingDetail = false"
        :id="selectItemId"
      ></BuildingDetail>
    </JJPop>
  </div>
</template>

<script>
import { getOption } from "./chart/option";
import { getOption2 } from "./chart/option2";
import * as echarts from "echarts";
import Swiper from "swiper";
import {
  estateDataCount,
  facilityInfo,
  citizenInfo,
  carInfo,
  zhongdianData,
  oldFocus,
  smartPolice,
  maodunData,
  abnormalData,
  nightRecord,
  collectData,
  enterpriseData,
} from "@/api/index";
import {
  zhongDianCount,
  liveCount,
  oldCount,
  shequCount,
  dangyuanCount,
  qiyeCount,
  hangyeCount,
  yuanquCount,
  fangwuCount,
  citizenList,
  citizenDetail,
  enterpriseList,
  enterpriseDetail,
  buildingList,
  buildingHouseDetail,
} from "@/api/indexCenter";
import JJPop from "../components/JJPop.vue";
import PersonDetail from "../components/PersonDetail.vue";
import EnterpriseDetail from "../components/EnterpriseDetail.vue";
import BuildingDetail from "../components/BuildingDetail.vue";
import MapContianer from "./MapOffline.vue"

export default {
  name: "Home",
  components: {
    JJPop,
    PersonDetail,
    EnterpriseDetail,
    BuildingDetail,
    MapContianer
  },
  data() {
    return {
      xiaoquData: {},
      facilityInfoData: {},
      citizenInfoData: {},
      carInfoData: {},
      zhongdianDataData: {},
      oldFocusData: {},
      smartPoliceData: {},
      maodunDataData: {},
      nightRecordData: {},
      collectDataData: {},
      enterpriseDataData: {},
      abnormalDataData: {},
      bottomMenuIndex: 0, // 底部菜单index
      bottomSecondMenuIndex: 0, // 底部二级菜单index
      bottomSecondMenuData: [],
      bottomSecondMenuList: [
        ["重点关注", "常住人口", "老幼关注", "社区人口", "党员及志愿者"],
        ["管理分类", "行业分类", "产业园区"],
        [],
        ["常住房屋"],
      ],
      centerTopCountData: [],
      showDialog: false,
      dialogSearch: {
        name: "",
        phone: "",
        idcard: "",
        address: "",
        page: 1,
        per: 7,
      },
      dialogListData: {
        data: [],
        total: 1,
      },
      showPersonDetail: false,
      showEnterpriseDetail: false,
      showBuildingDetail: false,
      selectItemId: "",
      bottomShow:false
    };
  },
  mounted() {
    this.getEstateDataCount();
    this.getFacilityInfo();
    this.getCitizenInfo();
    this.getCarInfo();
    this.getZhongdianData();
    this.getOldFocus();
    this.getSmartPolice();
    this.getMaodunData();
    this.getAbnormalData();
    this.getNightRecord();
    this.getCollectData();
    this.getEnterpriseData();

    this.bottomSecondMenuData = this.bottomSecondMenuList[0];
    this.selectBottomMenu(0);
  },
  methods: {
    showDetail(index, item) {
      if (index == 0) {
        this.selectItemId = item.citizen_id;
        this.showPersonDetail = true;
      } else if (index == 1) {
        this.selectItemId = item.enterprise_id;
        this.showEnterpriseDetail = true;
      } else if (index == 2) {
      } else if (index == 3) {
        this.selectItemId = item.building_id;
        this.showBuildingDetail = true;
      }
    },
    // 分页器回调
    paginationChange(res) {
      this.dialogSearch.page = res;
      if (this.bottomMenuIndex == 0) {
        this.getCitizenList();
      } else if (this.bottomMenuIndex == 1) {
        this.getEnterpriseList();
      } else if (this.bottomMenuIndex == 3) {
        this.getBuildingList();
      }
    },
    // 获取人员列表
    getCitizenList() {
      citizenList({
        name: this.dialogSearch.name,
        mobile: this.dialogSearch.phone,
        cardNo: this.dialogSearch.idcard,
        per: this.dialogSearch.per,
        page: this.dialogSearch.page,
      })
        .then((res) => {
          if (res.status === 1) {
            this.dialogListData = res.res;
          }
        })
        .catch(() => {
          this.dialogListData = {
            data: [],
            total: 1,
          };
        });
    },
    // 获取企业列表
    getEnterpriseList() {
      enterpriseList({
        name: this.dialogSearch.name,
        address: this.dialogSearch.address,
        per: this.dialogSearch.per,
        page: this.dialogSearch.page,
      })
        .then((res) => {
          if (res.status === 1) {
            this.dialogListData = res.res;
          }
        })
        .catch(() => {
          this.dialogListData = {
            data: [],
            total: 1,
          };
        });
    },
    // 获取房屋列表
    getBuildingList() {
      buildingList({
        name: this.dialogSearch.name,
        address: this.dialogSearch.address,
        per: this.dialogSearch.per,
        page: this.dialogSearch.page,
      })
        .then((res) => {
          if (res.status === 1) {
            this.dialogListData = res.res;
          }
        })
        .catch(() => {
          this.dialogListData = {
            data: [],
            total: 1,
          };
        });
    },
    // 搜索
    searchData() {
      if (this.bottomMenuIndex == 0) {
        this.getCitizenList();
      } else if (this.bottomMenuIndex == 1) {
        this.getEnterpriseList();
      } else if (this.bottomMenuIndex == 2) {
      } else if (this.bottomMenuIndex == 3) {
        this.getBuildingList();
      }
    },
    // 重置搜索
    resetDialogSearch() {
      this.dialogSearch = {
        name: "",
        phone: "",
        idcard: "",
        address: "",
        per: 7,
        page: 1,
      };
      this.searchData();
    },
    showDialogFun() {
      this.resetDialogSearch();
      this.showDialog = true;
      if (this.bottomMenuIndex == 0) {
        this.getCitizenList();
      } else if (this.bottomMenuIndex == 1) {
        this.getEnterpriseList();
      } else if (this.bottomMenuIndex == 2) {
      } else if (this.bottomMenuIndex == 3) {
        this.getBuildingList();
      }
    },
    // 点击中间最底部的菜单
    selectBottomMenu(index) {
      this.bottomMenuIndex = index;
      this.bottomSecondMenuData = this.bottomSecondMenuList[index];
      if (index == 0) {
        this.selectSecondMenu("重点关注", 0);
      } else if (index == 1) {
        this.selectSecondMenu("管理分类", 0);
      } else if (index == 2) {
        this.selectSecondMenu("", 0);
      } else if (index == 3) {
        this.selectSecondMenu("常住房屋", 0);
      }
    },
    // 点击中间底部的二级菜单
    selectSecondMenu(item, index) {
      this.bottomSecondMenuIndex = index;

      if (item == "重点关注") {
        zhongDianCount({}).then((res) => {
          if (res.status === 1) {
            this.centerTopCountData = [
              { title: "涉赌人员", num: res.res.duboNum },
              { title: "涉毒人员", num: res.res.xiduNum },
              { title: "涉稳重点", num: res.res.wendingNum },
              { title: "社区矫正", num: res.res.jiaozhengNum },
              { title: "违法犯罪前科", num: res.res.weifaNum },
            ];
          }
        });
      } else if (item == "常住人口") {
        liveCount({}).then((res) => {
          if (res.status === 1) {
            this.centerTopCountData = [
              { title: "单位法人", num: res.res.farenNum },
              { title: "安全员", num: res.res.anquanNum },
              { title: "单位联系人", num: res.res.lianxiNum },
              { title: "单位财务人员", num: res.res.caiwuNum },
              { title: "危化品管理员", num: res.res.weihuaNum },
            ];
          }
        });
      } else if (item == "老幼关注") {
        oldCount({}).then((res) => {
          if (res.status === 1) {
            this.centerTopCountData = [
              { title: "低保人员", num: res.res.dibaoNum },
              { title: "高龄老人", num: res.res.gaolingNum },
              { title: "空巢老人", num: res.res.kongchaoNum },
              { title: "残障人数", num: res.res.canzhangNum },
              { title: "留守儿童", num: res.res.liushouNum },
            ];
          }
        });
      } else if (item == "社区人口") {
        shequCount({}).then((res) => {
          if (res.status === 1) {
            this.centerTopCountData = [
              { title: "常住人口", num: res.res.changzhuNum },
              { title: "户籍人口", num: res.res.hujiNum },
              { title: "暂住人口", num: res.res.zanzhuNum },
              { title: "外籍人口", num: res.res.waijiNum },
              { title: "寄住人口", num: res.res.jizhuNum },
            ];
          }
        });
      } else if (item == "党员及志愿者") {
        dangyuanCount({}).then((res) => {
          if (res.status === 1) {
            this.centerTopCountData = [
              { title: "党员", num: res.res.dangyuanNum },
              { title: "楼道长", num: res.res.loudaoNum },
              { title: "社区保洁", num: res.res.baojieNum },
              { title: "社区保安", num: res.res.baoanNum },
              { title: "南河志愿者", num: res.res.zhiyuanNum },
              { title: "南河调节服务站", num: res.res.tiaojieNum },
            ];
          }
        });
      } else if (item == "管理分类") {
        qiyeCount({}).then((res) => {
          if (res.status === 1) {
            this.centerTopCountData = [
              { title: "沿街商户", num: res.res.yanjieNum },
              { title: "餐饮", num: res.res.canyinNum },
              { title: "销售型企业", num: res.res.xiaoshouNum },
              { title: "生产型企业", num: res.res.shengchanNum },
              { title: "日资企业", num: res.res.riziNum },
            ];
          }
        });
      } else if (item == "行业分类") {
        hangyeCount({}).then((res) => {
          if (res.status === 1) {
            this.centerTopCountData = [
              { title: "消防管理单位", num: res.res.xiaofangNum },
              { title: "易制毒", num: res.res.zhiduNum },
              { title: "易制爆", num: res.res.zhibaoNum },
              { title: "内保单位", num: res.res.neibaoNum },
              { title: "放射源企业", num: res.res.fangsheNum },
              { title: "涉恐重点", num: res.res.shekongNum },
            ];
          }
        });
      } else if (item == "产业园区") {
        yuanquCount({}).then((res) => {
          if (res.status === 1) {
            this.centerTopCountData = [
              { title: "南河商业街", num: res.res.shangyeNum },
              { title: "医疗产业孵化园", num: res.res.xitaihuNum },
              { title: "8号工业园", num: res.res.changyangNum },
              { title: "福隆控股", num: res.res.fulongNum },
              { title: "礼河综合市场", num: res.res.liheNum },
              { title: "南河家园企业", num: res.res.jiayuanNum },
            ];
          }
        });
      } else if (item == "常住房屋") {
        fangwuCount({}).then((res) => {
          if (res.status === 1) {
            this.centerTopCountData = [
              { title: "常住房屋", num: res.res.changzhuNum },
              { title: "整租房屋", num: res.res.zhengzuNum },
              { title: "合租房屋", num: res.res.hezuNum },
              { title: "群租房屋", num: res.res.qunzuNum },
              { title: "智能锁房屋", num: res.res.zhinengNum },
            ];
          }
        });
      } else {
        this.centerTopCountData = [];
      }
    },
    getEstateDataCount() {
      estateDataCount({}).then((res) => {
        if (res.status === 1) {
          this.xiaoquData = res.res;
          setTimeout(function () {
            new Swiper(".swiper-container", {
              slidesPerView: 2,
              loop: false,
              mousewheel: true,
              keyboard: true,
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            });
          }, 1000);
        }
      });
    },
    getFacilityInfo() {
      facilityInfo({}).then((res) => {
        if (res.status === 1) {
          this.facilityInfoData = res.res;
          var seriesData = [
            { value: res.res.jiankong_num, name: "监控总数" },
            { value: res.res.laji_num, name: "垃圾配套" },
            { value: res.res.dog_num, name: "犬只数量" },
            { value: res.res.xiaofang_num, name: "消防设施" },
            { value: res.res.kuaidi_num, name: "快递驿站" },
            { value: res.res.menjin_num, name: "人像门禁" },
          ];
          this.initSSEcharts(seriesData);
        }
      });
    },
    getCitizenInfo() {
      citizenInfo({}).then((res) => {
        if (res.status === 1) {
          this.citizenInfoData = res.res;
          var seriesData = [
            { value: res.res.total_num, name: "常住人口" },
            { value: res.res.huji_num, name: "户籍人口" },
            { value: res.res.waiji_num, name: "外籍人口" },
            { value: res.res.zanzhu_num, name: "暂住人口" },
            { value: res.res.jizhu_num, name: "寄住人口" },
          ];
          this.initEcharts(seriesData);
        }
      });
    },
    getCarInfo() {
      carInfo({}).then((res) => {
        if (res.status === 1) {
          this.carInfoData = res.res;
          var seriesData = [
            { value: res.res.total, name: "登记车辆" },
            { value: res.res.bendi, name: "本地车牌" },
            { value: res.res.waidi, name: "外地车牌" },
            { value: res.res.lvpai, name: "绿牌车牌" },
            { value: res.res.huangpai, name: "黄牌车辆" },
          ];
          this.initCLEcharts(seriesData);
        }
      });
    },
    //
    getZhongdianData() {
      zhongdianData({ limit: 4 }).then((res) => {
        if (res.status === 1) {
          this.zhongdianDataData = res.res;
        }
      });
    },
    getOldFocus() {
      oldFocus({ limit: 4 }).then((res) => {
        if (res.status === 1) {
          this.oldFocusData = res.res;
        }
      });
    },
    getSmartPolice() {
      smartPolice({}).then((res) => {
        if (res.status === 1) {
          this.smartPoliceData = res.res;
          var seriesData = [
            { value: res.res.zxcz_num, name: "专项处置数量" },
            { value: res.res.mdlt_num, name: "矛盾联调数量" },
            { value: res.res.yhlp_num, name: "隐患联排数量" },
            { value: res.res.jqlc_num, name: "警情联处数量" },
            { value: res.res.fflx_num, name: "防范联宣数量" },
          ];
          this.initZHEcharts(seriesData);
        }
      });
    },
    getMaodunData() {
      maodunData({ limit: 3 }).then((res) => {
        if (res.status === 1) {
          this.maodunDataData = res.res;
        }
      });
    },
    getAbnormalData() {
      abnormalData({ limit: 3 }).then((res) => {
        if (res.status === 1) {
          this.abnormalDataData = res.res;
        }
      });
    },
    getEnterpriseData() {
      enterpriseData({}).then((res) => {
        if (res.status === 1) {
          this.enterpriseDataData = res.res;
          var seriesData = [
            {
              value: [
                res.res.qiyeNum,
                res.res.neibaoNum,
                res.res.xiaofangNum,
                res.res.xiyuNum,
                res.res.qipaiNum,
                res.res.canyinNum,
                res.res.shagnhuNum,
                res.res.gonggongNum,
                res.res.danweiNum,
              ],
              //symbol: "rect",
              symbolSize: 1,
              symbolColor: "#47EDFC",
              //symbol: "none", //去掉折线上的小圆点
              // areaStyle: { color: "rgba(71,237,252,.3)" }, //雷达覆盖区域背景颜色
              lineStyle: {
                color: "rgba(11, 200, 255, 1)",
              },
              // itemStyle: {
              //     color: "#47EDFC",
              // },
              // label: {
              //     show: false,
              //     formatter: function (params) {
              //         return params.value;
              //     },
              // },
            },
          ];
          this.initJwEcharts(seriesData);
        }
      });
    },
    getNightRecord() {
      nightRecord({ limit: 4 }).then((res) => {
        if (res.status === 1) {
          this.nightRecordData = res.res;
        }
      });
    },
    getCollectData() {
      collectData({ limit: 4 }).then((res) => {
        if (res.status === 1) {
          this.collectDataData = res.res;
        }
      });
    },
    initEcharts(seriesData) {
      echarts.dispose(document.getElementById("rychart"));
      var chartDom = document.getElementById("rychart");
      var myChart = echarts.init(chartDom);
      let option = getOption({ seriesData: seriesData });
      option && myChart.setOption(option);
    },

    initSSEcharts(seriesData) {
      echarts.dispose(document.getElementById("ss_chart"));
      var chartDom = document.getElementById("ss_chart");
      var myChart = echarts.init(chartDom);
      let option = getOption({ seriesData: seriesData });
      option && myChart.setOption(option);
    },
    initCLEcharts(seriesData) {
      echarts.dispose(document.getElementById("clchart"));
      var chartDom = document.getElementById("clchart");
      var myChart = echarts.init(chartDom);
      let option = getOption({ seriesData: seriesData });
      option && myChart.setOption(option);
    },
    initZHEcharts(seriesData) {
      echarts.dispose(document.getElementById("zh_chart"));
      var chartDom = document.getElementById("zh_chart");
      var myChart = echarts.init(chartDom);
      let option = getOption({ seriesData: seriesData });
      option && myChart.setOption(option);
    },
    initJwEcharts(seriesData) {
      echarts.dispose(document.getElementById("jw_chart"));
      var chartDom = document.getElementById("jw_chart");
      var myChart = echarts.init(chartDom);
      let option = getOption2({ seriesData: seriesData });
      option && myChart.setOption(option);
    },
    //jw_chart
    //

  },
};
</script>
<style lang="less">
@import "../assets/css/index.less";
@import "../assets/css/indexPop.less";
</style>



