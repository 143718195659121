<template>
	<div class="dialog-view">
		<div class="dialog-view-header">
			<div class="header-left">
				<div class="line"></div>
				<div class="title">人员详情</div>
			</div>
			<img @click="closeView" class="close-btn" src="~@/assets/images/center3.png" />
		</div>
		<div class="dialog-view-select">
			<div class="select-item select-action">基本信息</div>
			<div class="select-item">人员关系</div>
			<div class="select-item">走访记录</div>
			<div class="select-item">房屋信息</div>
			<div class="select-item">涉事信息</div>
			<div class="select-item">涉案信息</div>
			<div class="select-item">涉警信息</div>
			<div class="select-item">信用积分</div>
		</div>
		
		<div class="detail-view">
			<div class="detail-view-img">
				<img class="img" :src="info.avatar_url">
			</div>
			<div class="info-view">
				<div class="name-view">
					<div class="name">
						姓名：{{info.name}}
					</div>
					<div class="label-view" v-for="(item,index) in info.labels" :style="{'background-image': `url(${require('@/assets/images/xq5.png')})`}" >
						{{item.label_name}}
					</div>
				</div>
				<div class="info-line">
					<div class="title">手机号码：</div>
					<div class="num-value">{{info.mobile_hidden}}</div>
					<div class="fengexian"></div>
					<div class="title">身份证号：</div>
					<div class="num-value">{{info.paper_number_hidden}}</div>
				</div>
				<div class="info-line">
					<div class="title">文化程度：</div>
					<div v-if="info.education" class="title">{{info.education}}</div>
					<div class="fengexian"></div>
					<div class="title">人口类型：</div>
					<div v-if="info.citizen_type" class="title">{{info.citizen_type}}</div>
					<div class="fengexian"></div>
					<div class="title">婚姻状况：</div>
					<div v-if="info.marriage" class="title">{{info.marriage}}</div>
					<div class="fengexian"></div>
					<div class="title">政治面貌：</div>
					<div v-if="info.political" class="title">{{info.political}}</div>
				</div>
				<div class="xuxian"></div>
				<div class="info-line" style="margin-top: 0;">
					<img class="address-icon" src="../assets/images/xq3.png" alt="">
					<div class="address-text">户籍地址：{{info.live_area_code_address.city_name}} {{info.live_area_code_address.area_name}}</div>
				</div>
				<div class="info-line"  >
					<img class="address-icon" src="../assets/images/xq3.png" alt="">
					<div class="address-text">居住地址：{{info.live_address}}</div>
				</div>
				<div class="xuxian"></div>
				<div class="info-line" style="margin-top: 0;">
					<div class="title">住房来源：</div>
					<div v-if="info.house_come_type" class="title">{{info.house_come_type}}</div>
					<div class="fengexian"></div>
					<div class="title">工作单位：</div>
					<div v-if="info.work_company" class="title">{{info.work_company}}</div>
				</div>
			</div>
		</div>	


	</div>
</template>


<script>
	
	import {citizenDetail} from '@/api/indexCenter'
	
	export default {
		name: 'PersonDetail',
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				info:{}
			};
		},
		mounted(){
			this.getDetail()
		},
		methods: {
			getDetail(){
				citizenDetail({
					citizen_id: this.id,
				}).then((res) => {
				    if (res.status === 1) {
						this.info = res.res
						// if(this.info.labels && this.info.labels.length>0)
						// {
						// 	let label = ''
						// 	this.info.labels.forEach((item,index)=>{
						// 		label+= item.label_name + ' '
						// 	})
						// 	this.info.labelStr = label
						// }
						
						if(this.info.education)
						{
							// 学历，1-小学 2-初中 3-高中 4-中专 5-大专 6-本科 7-硕士 8-博士
							let str = ''
							switch (this.info.education){
								case 1:
									str = '小学'
									break;
								case 2:
									str = '初中'
									break;
								case 3:
									str = '高中'
									break;
								case 4:
									str = '中专'
									break;
								case 5:
									str = '大专'
									break;
								case 6:
									str = '本科'
									break;	
								case 7:
									str = '硕士'
									break;	
								case 8:
									str = '博士'
									break;							
								default:
									break;
							}
							this.info.education = str
						}
						
						if(this.info.citizen_type)
						{
							// 1.常住人口；2.暂住人口；3.流动人口
							let str = ''
							switch (this.info.citizen_type){
								case 1:
									str = '常住人口'
									break;
								case 2:
									str = '暂住人口'
									break;
								case 3:
									str = '流动人口'
									break;						
								default:
									break;
							}
							this.info.citizen_type = str
						}
						
						if(this.info.marriage)
						{
							// 1-未婚 2-已婚 3-离异 4-丧偶
							let str = ''
							switch (this.info.marriage){
								case 1:
									str = '未婚'
									break;
								case 2:
									str = '已婚'
									break;
								case 3:
									str = '离异'
									break;		
								case 4:
									str = '丧偶'
									break;						
								default:
									break;
							}
							this.info.marriage = str
						}
						
						if(this.info.political)
						{
							// 1-党员 2-预备党员 3-志愿者 4-群众 5-团员 6-无党派人士
							let str = ''
							switch (this.info.political){
								case 1:
									str = '党员'
									break;
								case 2:
									str = '预备党员'
									break;
								case 3:
									str = '志愿者'
									break;		
								case 4:
									str = '群众'
									break;		
								case 5:
									str = '团员'
									break;		
								case 6:
									str = '无党派人士'
									break;						
								default:
									break;
							}
							this.info.political = str
						}
						
						if(this.info.house_come_type)
						{
							// 1.自购；2.租住；3.借住
							let str = ''
							switch (this.info.house_come_type){
								case 1:
									str = '自购'
									break;
								case 2:
									str = '租住'
									break;
								case 3:
									str = '借住'
									break;							
								default:
									break;
							}
							this.info.house_come_type = str
						}

				    }
				 })
			},
			closeView() {
				this.$emit("close")
			}

		}
	};
</script>


<style scoped lang="less">
	.dialog-view {
		width: 65rem;
		height: 43rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-image: url("../assets/images/center1.png");
		background-size: 100% 100%;
		padding: 0 2rem;

		.dialog-view-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 96%;
			padding: 0 1.7rem;
			margin-top: 5rem;

			.header-left {
				display: flex;
				flex-direction: row;

				.line {
					width: .4rem;
					height: 1.2rem;
					background: linear-gradient(180deg, #FFFFFF 0%, #06F8FF 100%);
					box-shadow: .15rem 0rem .4rem 0rem rgba(22, 248, 255, 0.8);
				}

				.title {
					font-size: 1.3rem;
					font-family: SourceHanSansCN, SourceHanSansCN;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 1.4rem;
					margin-left: 1rem;
				}
			}

			.close-btn {
				width: 1.6rem;
				height: 1.6rem;
			}
		}

		.dialog-view-select {
			display: flex;
			flex-direction: row;
			width: 90%;
			margin-top: 1.5rem;
			align-items: center;
			justify-content: center;

			.select-item {
				width: 10%;
				height: 3rem;
				line-height: 3rem;
				text-align: center;
				font-size: 1.1rem;
				color: #fff;
				margin: 0 .6rem;
				
				background: #0E2752;
				box-shadow: inset 0rem 0rem .5rem 0rem rgba(255,255,255,0.2);
				border-radius: .2rem;
				border: .1rem solid;
				border-image: linear-gradient(180deg, rgba(62, 142, 216, 1), rgba(25, 74, 153, 1)) 2 2;
			}

			.select-action {
				background: linear-gradient(180deg, #185197 0%, #0B2B5D 100%);
				box-shadow: inset 0rem 0rem .6rem 0rem rgba(80,167,255,0.59);
				border-radius: .2rem;
				border: .1rem solid;
				border-image: linear-gradient(180deg, rgba(62, 142, 216, 1), rgba(25, 74, 153, 1)) 2 2;
			}
		}

		.detail-view {
			display: flex;
			flex-direction: row;
			margin-top: 1.5rem;
			width: 57.5rem;
			height: 26rem;
			background: linear-gradient(90deg, rgba(1,38,94,0.8) 0%, rgba(0,24,61,0.2) 100%);
			box-shadow: inset 0rem 0rem .8rem 0rem rgba(77,91,149,0.4);
			border-radius: .2rem;
			border: .05rem solid;
			border-image: linear-gradient(90deg, rgba(33, 95, 193, 1), rgba(33, 95, 193, 0.4)) 1 1;
			padding: 1.5rem;

			.detail-view-img {
				width: 9rem;
				height: 11.5rem;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				border-radius: .25rem;
				overflow: hidden;
				.img {
					width: 9rem;
					height: 11.5rem;
					background-color: #fff;
					object-fit: contain;
				}
			}
			.info-view{
				width: 45rem;
				height: 23.5rem;
				margin-left: 1.5rem;
				display: flex;
				flex-direction: column;
				.name-view{
					display: flex;
					flex-direction: row;
					align-items: center;
					.name{
						font-size: 1.4rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: bold;
						color: #FFFFFF;
						margin-right: .5rem;
					}
					.label-view{
						font-size: 1.2rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: 500;
						color: rgba(222, 241, 255, 1);
						text-shadow: 0rem .05rem .15rem rgba(0,0,0,0.4);
						padding-top: .5rem;
						padding-left: 1rem;
						margin-left: .9rem;
						background-repeat: no-repeat;
					}
				}
				.info-line{
					margin-top: 1.3rem;
					display: flex;
					flex-direction: row;
					align-items: center;
					.title{
						font-size: 1.2rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: 400;
						color: #DEF1FF;
					}
					.num-value{
						margin-left: .3rem;
						font-size: 1.2rem;
						font-family: Impact;
						color: #FFFFFF;
						background: linear-gradient(180deg, #FFFFFF 0%, #7BDCFF 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					.fengexian{
						width: .1rem;
						height: 1.2rem;
						background-color: #ccc;
						margin: 0 1rem;
					}
					.address-icon{
						width: 1.1rem;
						height: 1.1rem;
					}
					.address-text{
						font-size: 1.2rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: 400;
						color: #DEF1FF;
						margin-left: .5rem;
					}
				}
				.xuxian{
					border-top: .05rem dashed #ccc;
					width: 42.5rem;
					height: 0;
					margin: 1.5rem 0;

				}
				
			}
		}


	}
</style>