<template>
	<div class="dialog-view">
		<div class="dialog-view-header">
			<div class="header-left">
				<div class="line"></div>
				<div class="title">企业详情</div>
			</div>
			<img @click="closeView" class="close-btn" src="~@/assets/images/center3.png" />
		</div>
		<div class="dialog-view-select">
			<div class="select-item select-action">基本信息</div>
			<div class="select-item">从业人员</div>
			<div class="select-item">检查项目</div>
			<div class="select-item">走访记录</div>
			<div class="select-item">涉事信息</div>
			<div class="select-item">信用积分</div>
		</div>
		
		<div class="detail-view">
			<div class="detail-view-img">
				<img class="img" :src="info.enterprise_images">
			</div>
			<div class="info-view">
				<div class="name-view">
					<div class="name">
						{{info.enterprise_name}}
					</div>
					<div class="yyzz" @click="showBigImage">
						<img class="img" src="../assets/images/xq1.png" alt="">
						<div class="text">营业执照</div>
					</div>
				</div>
				<div class="info-line">
					<div class="title">营业执照：</div>
					<div class="num-value">{{info.license_number}}</div>
					<div class="fengexian"></div>
					<div class="title">负责人：</div>
					<div class="num-value">{{info.charge_citizen_name}}</div>
					<div class="fengexian"></div>
					<div class="title">电话：</div>
					<div class="num-value">{{info.charge_citizen_mobile}}</div>
				</div>
				<div class="xuxian"></div>
				<div class="info-line" style="margin-top: 0;">
					<img class="address-icon" src="../assets/images/xq3.png" alt="">
					<div class="address-text">办公地址：{{info.city_name}} {{info.area_name}} {{info.street_name}} {{info.address}}</div>
				</div>
				<div class="info-line" style="align-items: flex-start;">
					<img class="address-icon" src="../assets/images/xq4.png" alt="">
					<div class="address-text" style="margin-top: -0.2rem;">经营范围：</div>
				</div>
				<div class="xuxian"></div>
				<div class="info-line" style="margin-top: 0;">
					<div class="title">管理单位：</div>
					<div v-if="info.street_name" class="num-value">{{info.street_name}}</div>
					<div class="fengexian"></div>
					<div class="title">管理标签：</div>
					<div v-for="(item,index) in info.manage_labels" class="num-value">{{item.label_name}}</div>
				</div>
				<div class="info-line">
					<div class="title">行业标签：</div>
					<div v-for="(item,index) in info.business_labels" class="num-value">{{item.label_name}}</div>
					<div class="fengexian"></div>
					<div class="title">园区标签：</div>
					<div v-for="(item,index) in info.park_labels" class="num-value">{{item.label_name}}</div>
				</div>
			</div>
		</div>	
		<JJPop :isShow="showImg" @tapMask="showImg=false">
			<img class="bigimg" :src="info.license_images" alt="">
		</JJPop>
	</div>	
	
	
	
</template>

<script>
	import {enterpriseDetail} from '@/api/indexCenter'
	import JJPop from "/src/components/JJPop.vue";
	export default {
		name: 'EnterpriseDetail',
		components: {
		  JJPop,
		},
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				info:{},
				showImg:false,
			};
		},
		mounted(){
			this.getDetail()
		},
		methods: {
			getDetail(){
				enterpriseDetail({
					enterprise_id: this.id,
				}).then((res) => {
				    if (res.status === 1) {
						this.info = res.res
				    }
				 })
			},
			closeView() {
				this.$emit("close")
			},
			showBigImage(){
				if(this.info.license_images)
				{
					this.showImg = true
				}
			}
	
		}
	};
</script>

<style scoped lang="less">
	.dialog-view {
		width: 65rem;
		height: 43rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-image: url("../assets/images/center1.png");
		background-size: 100% 100%;
		padding: 0 2rem;

		.dialog-view-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 96%;
			padding: 0 1.7rem;
			margin-top: 5rem;

			.header-left {
				display: flex;
				flex-direction: row;

				.line {
					width: .4rem;
					height: 1.2rem;
					background: linear-gradient(180deg, #FFFFFF 0%, #06F8FF 100%);
					box-shadow: .15rem 0rem .4rem 0rem rgba(22, 248, 255, 0.8);
				}

				.title {
					font-size: 1.3rem;
					font-family: SourceHanSansCN, SourceHanSansCN;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 1.4rem;
					margin-left: 1rem;
				}
			}

			.close-btn {
				width: 1.6rem;
				height: 1.6rem;
			}
		}

		.dialog-view-select {
			display: flex;
			flex-direction: row;
			width: 90%;
			margin-top: 1.5rem;
			align-items: center;
			justify-content: center;

			.select-item {
				width: 10%;
				height: 3rem;
				line-height: 3rem;
				text-align: center;
				font-size: 1.1rem;
				color: #fff;
				margin: 0 .6rem;
				
				background: #0E2752;
				box-shadow: inset 0rem 0rem .5rem 0rem rgba(255,255,255,0.2);
				border-radius: .2rem;
				border: .1rem solid;
				border-image: linear-gradient(180deg, rgba(62, 142, 216, 1), rgba(25, 74, 153, 1)) 2 2;
			}

			.select-action {
				background: linear-gradient(180deg, #185197 0%, #0B2B5D 100%);
				box-shadow: inset 0rem 0rem .6rem 0rem rgba(80,167,255,0.59);
				border-radius: .2rem;
				border: .1rem solid;
				border-image: linear-gradient(180deg, rgba(62, 142, 216, 1), rgba(25, 74, 153, 1)) 2 2;
			}
		}

		.detail-view {
			display: flex;
			flex-direction: row;
			margin-top: 1.5rem;
			width: 57.5rem;
			height: 26rem;
			background: linear-gradient(90deg, rgba(1,38,94,0.8) 0%, rgba(0,24,61,0.2) 100%);
			box-shadow: inset 0rem 0rem .8rem 0rem rgba(77,91,149,0.4);
			border-radius: .2rem;
			border: .05rem solid;
			border-image: linear-gradient(90deg, rgba(33, 95, 193, 1), rgba(33, 95, 193, 0.4)) 1 1;
			padding: 1.5rem;

			.detail-view-img {
				border-radius: .25rem;
				overflow: hidden;
				.img {
					width: 9rem;
					height: 9rem;
					object-fit: contain;
				}
			}
			.info-view{
				width: 45rem;
				height: 23.5rem;
				margin-left: 1.5rem;
				display: flex;
				flex-direction: column;
				.name-view{
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					.name{
						font-size: 1.4rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: bold;
						color: #FFFFFF;
						margin-right: .5rem;
					}
					.yyzz{
						display: flex;
						flex-direction: row;
						align-items: center;
						.img{
							width: 1.2rem;
							height: 1.2rem;
						}
						.text{
							font-size: 1.3rem;
							font-family: SourceHanSansCN, SourceHanSansCN;
							font-weight: 400;
							color: #DEF1FF;
							background: linear-gradient(180deg, #FFFFFF 0%, #FFD200 100%);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							margin-left: .5rem;
						}
					}
					.label-view{
						font-size: 1.2rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: 500;
						color: rgba(222, 241, 255, 1);
						text-shadow: 0rem .05rem .15rem rgba(0,0,0,0.4);
						padding-top: .5rem;
						padding-left: 1rem;
						margin-left: .9rem;
						background-repeat: no-repeat;
					}
				}
				.info-line{
					margin-top: 1.3rem;
					display: flex;
					flex-direction: row;
					align-items: center;
					.title{
						font-size: 1.2rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: 400;
						color: #DEF1FF;
					}
					.num-value{
						margin-left: .3rem;
						font-size: 1.2rem;
						font-family: Impact;
						color: #FFFFFF;
						background: linear-gradient(180deg, #FFFFFF 0%, #7BDCFF 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					.fengexian{
						width: .1rem;
						height: 1.2rem;
						background-color: #ccc;
						margin: 0 1.5rem;
					}
					.address-icon{
						width: 1.1rem;
						height: 1.1rem;
					}
					.address-text{
						font-size: 1.2rem;
						font-family: SourceHanSansCN, SourceHanSansCN;
						font-weight: 400;
						color: #DEF1FF;
						margin-left: .5rem;
					}
				}
				.xuxian{
					border-top: .05rem dashed #ccc;
					width: 42.5rem;
					height: 0;
					margin: 1.5rem 0;

				}
				
			}
		}


	}
	.bigimg{
		width: 56.8rem;
		height: 40rem;
	}
</style>