<template>
  <div class="home" id="leafletMap"></div>
</template>

<script>
import L from 'leaflet'

require('../utils/tileLayer.baidu.js')
export default {
  name: 'Home',
  data() {
    return {}
  },
  methods: {
    initMap() {
      var corner1 =  L.latLng(31.338441,119.678462); //设置左上角经纬度
var corner2 = L.latLng(31.814543,120.207689);	//设置右下点经纬度
var bounds = L.latLngBounds(corner1, corner2);
      let map = L.map('leafletMap', {
        minZoom: 11,
        maxZoom: 16,
        center: [31.661477,119.930048],
        zoom: 12,
        zoomControl: false,
        attributionControl: false,
        crs: L.CRS.Baidu,
        maxBounds: bounds,
      })

      L.tileLayer.baidu({ layer: 'vec' }).addTo(map)
    },
  },
  mounted() {
    this.initMap()
  },
}
</script>

<style lang="less" scoped>
html,
body,
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>>
