import request from '@/utils/request'


// 小区信息
export function estateDataCount (parameter) {
  return request({
    url: '/Screen/Wj/estateDataCount',
    method: 'post',
    data: parameter
  })
}


// 社区设施数量
export function facilityInfo (parameter) {
  return request({
    url: '/Screen/Wj/facilityInfo',
    method: 'post',
    data: parameter
  })
}


// 人员信息
export function citizenInfo (parameter) {
  return request({
    url: '/Screen/Wj/citizenInfo',
    method: 'post',
    data: parameter
  })
}


// 车辆管理
export function carInfo (parameter) {
  return request({
    url: '/Screen/Wj/carInfo',
    method: 'post',
    data: parameter
  })
}

// 重点人员管控
export function zhongdianData (parameter) {
  return request({
    url: '/Screen/Wj/zhongdianData',
    method: 'post',
    data: parameter
  })
}

// 老幼关注
export function oldFocus (parameter) {
  return request({
    url: '/Screen/Wj/oldFocus',
    method: 'post',
    data: parameter
  })
}

// 智慧警网
export function smartPolice (parameter) {
  return request({
    url: '/Screen/Wj/smartPolice',
    method: 'post',
    data: parameter
  })
}

//企业信息
export function enterpriseData (parameter) {
  return request({
    url: '/Screen/Wj/enterpriseData',
    method: 'post',
    data: parameter
  })
}

//矛盾防范
export function maodunData (parameter) {
  return request({
    url: '/Screen/Wj/maodunData',
    method: 'post',
    data: parameter
  })
}

//异常活动监测
export function abnormalData (parameter) {
  return request({
    url: '/Screen/Wj/abnormalData',
    method: 'post',
    data: parameter
  })
}


//深夜出行
export function nightRecord (parameter) {
  return request({
    url: '/Screen/Wj/nightRecord',
    method: 'post',
    data: parameter
  })
}


//精准采集
export function collectData (parameter) {
  return request({
    url: '/Screen/Wj/collectData',
    method: 'post',
    data: parameter
  })
}
