require('proj4')
require('proj4leaflet')
const L = require('leaflet')
// 本地百度地图离线瓦片地图
const titleD = process.env.VUE_APP_PUBLIC_PATH + 'map/{z}/{x}/{y}.png'

var urlPath = titleD
//请引入 proj4.js 和 proj4leaflet.js
L.CRS.Baidu = new L.Proj.CRS(
  'EPSG:900913',
  '+proj=merc +a=6378206 +b=6356584.314245179 +lat_ts=0.0 +lon_0=0.0 +x_0=0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs',
  {
    resolutions: (function () {
      const level = 19
      var res = []
      res[0] = Math.pow(2, 18)
      for (var i = 1; i < level; i++) {
        res[i] = Math.pow(2, 18 - i)
      }
      return res
    })(),
    origin: [0, 0],
    bounds: L.bounds([20037508.342789244, 0], [0, 20037508.342789244]),
  }
)

L.tileLayer.baidu = function (option) {
  option = option || {}

  var layer
  var subdomains = '0123456789'
  switch (option.layer) {
    //单图层
    case 'vec':
    default:
      layer = L.tileLayer(urlPath, {
        name: option.name,
        subdomains: subdomains,
        tms: true,
      })
      break
    
  }
  return layer
}
